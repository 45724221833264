<template>
  <div class="reset-pwd-container">
    <div class="reset-pwd col-center">
      <p class="reset-pwd-title">{{ step == 1 ? '原有资产密码' : step == 2 ? '请输入新的资产密码' : '请再次输入资产密码'}}</p>
      <van-password-input
        :value="step == 1 ?  oldPwd : step == 2 ? newPwd : repeatPwd"
        :gutter="10"
        :focused="showKeyboard"
        @focus="showKeyboard = true"
      />
      <p
        v-if="step == 2 && !hasAssetsPassword"
        class="reset-pwd-tip"
      >账户还未设置资产密码，密码为6位纯数字。<br />建议不要使用生日、连续数字等</p>
      <p
        v-else
        class="reset-pwd-tip"
      >{{ step == 1 ? '请输入原资产密码，验证账户' : step == 2 && hasAssetsPassword ? '密码为6位纯数字，建议不要使用生日、连续数字等' : step == 3 ? '请再次输入资产密码' : '账户还未设置资产密码，密码为6位纯数字。建议不要使用生日、连续数字等' }}</p>
      <van-button
        v-if="isShowSetAssetsPassword"
        @click="setAssetsPassword"
        round
        block
        type="info"
        native-type="submit"
      >确 定</van-button>
      <!-- 数字键盘 -->
      <van-number-keyboard
        v-if="step == 1"
        v-model="oldPwd"
        :show="showKeyboard"
        @blur="showKeyboard = false"
      />
      <van-number-keyboard
        v-if="step == 2"
        v-model="newPwd"
        :show="showKeyboard"
        @blur="showKeyboard = false"
      />
      <van-number-keyboard
        v-if="step == 3"
        v-model="repeatPwd"
        :show="showKeyboard"
        @blur="showKeyboard = false"
      />
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import api from '../api/index-client'
import { PasswordInput,NumberKeyboard,Button } from 'vant';
import md5 from 'js-md5';

Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
Vue.use(Button);
export default {
  data () {
    return {
      showKeyboard: false,
      step: 1,
      isShowSetAssetsPassword: false,
      oldPwd: '',
      newPwd: '',
      repeatPwd: '',
      phoneNum: '',
      hasAssetsPassword: false,
    }
  },
  watch: {
    oldPwd () {
      if (this.oldPwd.length === 6) {
        this.testOldPwd()
      }
    },
    newPwd () {
      if (this.newPwd.length === 6) {
        this.step = 3
      }
    },
    repeatPwd () {
      if (this.repeatPwd.length === 6) {
        if (this.repeatPwd !== this.newPwd) {
          this.repeatPwd = ''
          this.$toast.fail('两次密码输入不一致')
        } else {
          this.isShowSetAssetsPassword = true
        }
        // this.step = 3
        // 调用修改密码接口
      }
    }
  },
  mounted () {
    this.$store.commit('SHOW_APPLOADING')
    this.getMyInfo()
    if (this.$route.query.code) {

    } else {
      // 获取个人资料
    }

  },
  methods: {
    // 获取个人基本信息
    getMyInfo () {
      api
        .get('userinfo')
        .then(result => {
          if (result.data.success) {
            this.hasAssetsPassword = result.data.data.hasAssetsPassword
            if (this.hasAssetsPassword) {
              this.$store.commit('SHOW_ISSETPWD')
            } else {
              this.$store.commit('HIDE_ISSETPWD')
            }
            if (this.$route.query.code) {
              this.step = 2
            } else {
              this.step = this.hasAssetsPassword ? 1 : 2
            }
            this.phoneNum = result.data.data.phone
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
          this.$store.commit('HIDE_APPLOADING')
        })
    },
    testOldPwd () {
      api
        .post('user/verifyAssetsPassword',{ assetsPassword: md5(this.oldPwd) })
        .then(result => {
          if (result.data.success) {
            this.step = 2
          } else {
            this.oldPwd = ''
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
          this.$store.commit('HIDE_APPLOADING')
        })
    },
    setAssetsPassword () {
      this.showKeyboard = false
      this.$store.commit('SHOW_APPLOADING')
      var oldAssetsPassword = this.oldPwd ? md5(this.oldPwd) : '',
        assetsPassword = this.newPwd ? md5(this.newPwd) : ''
      if (!this.$route.query.code) {
        api
          .post('user/setAssetsPassword',{ oldAssetsPassword: oldAssetsPassword,assetsPassword: assetsPassword })
          .then(result => {
            if (result.data.success) {
              this.$router.replace('/set')
              this.$toast.success('资产密码设置成功！')
            } else {
              this.newPwd = ''
              this.oldPwd = ''
              this.repeatPwd = ''
              if (this.$route.query.code) {
                this.step = 2
              } else {
                this.step = this.hasAssetsPassword ? 1 : 2
              }
              this.$toast({
                message: result.data.msg,
                icon: require('../assets/icon/toast-error.png'),
              });
            }
            this.$store.commit('HIDE_APPLOADING')
          })
      } else {
        api
          .post('user/findAssetsPassword',{ phone: this.phoneNum,code: this.$route.query.code,oldAssetsPassword: oldAssetsPassword,assetsPassword: assetsPassword })
          .then(result => {
            if (result.data.success) {
              this.$router.replace('/set')
              this.$toast.success('资产密码设置成功！')
            } else {
              this.newPwd = ''
              this.oldPwd = ''
              this.repeatPwd = ''
              if (this.$route.query.code) {
                this.step = 2
              } else {
                this.step = this.hasAssetsPassword ? 1 : 2
              }
              this.$toast({
                message: result.data.msg,
                icon: require('../assets/icon/toast-error.png'),
              });
            }
            this.$store.commit('HIDE_APPLOADING')
          })
      }

    },
  },
}
</script>
<style lang="less" scoped>
.reset-pwd-container {
  margin-top: 3.875rem;
  height: calc(100vh - 3.875rem);
  overflow-y: auto;
  width: 100%;
  z-index: 19;

  .reset-pwd {
    width: 90%;
    margin: 0 5%;
    padding-bottom: 1rem;
    background: #1c1c1c;
    .reset-pwd-title {
      margin-top: 2.25rem;
      width: 100%;
      text-align: left;
      font-size: 1.375rem;
      font-family: lantingheiweight;
      font-weight: 300;
      color: #ffffff;
      line-height: 31px;
      letter-spacing: 1px;
      margin-bottom: 1.3125rem;
    }
    .reset-pwd-tip {
      width: 100%;
      font-size: 0.875rem;
      font-weight: 500;
      color: #bfbfbf;
    }
    .van-password-input {
      width: 100%;
      margin: 0;
      margin-bottom: 1.375rem;
    }
    .van-password-input__security li {
      height: 100%;
      border: 1px solid rgba(158, 158, 158, 100);
      background: #454545;
      border-radius: 0.25rem;
      border: none;
      outline: none;
    }
    .van-number-keyboard {
      background: rgba(21, 21, 21, 0.85);
    }
    /deep/.van-number-keyboard .van-key {
      background-color: #646465 !important;
      box-shadow: 0px 1px 0px 0px #000000 !important;
      font-family: Helvetica;
      color: #ffffff !important;
    }
    .van-button {
      margin-top: 2.25rem;
      height: 2.875rem;
      background-image: url('../assets/icon/confirm-button.png');
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: 0% 0%;
      background-size: 100% auto;
      background-color: transparent;
      font-size: 1rem;
      font-family: lantingheiweight;
      font-weight: normal;
      color: #ffffff;
      line-height: 1.375rem;
      border-radius: 0;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }
}
</style>